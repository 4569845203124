import React from 'react';
import * as Flex from '@twilio/flex-ui';
import { AMCRoot } from './AMCRoot';

class App extends React.Component {
  render() {
    const { manager, config } = this.props;

    if (!manager) {
      return null;
    }

    return (
      <Flex.ContextProvider manager={manager}>
        {/*AMC - DO NOT Load FlexUI <Flex.RootContainer />  Load AMC UI below*/}
        <AMCRoot manager={manager} config={config} />
      </Flex.ContextProvider>
    );
  }
}

export default App;
