import React from 'react';
import ReactDOM from 'react-dom';
import 'regenerator-runtime/runtime';
import * as Flex from '@twilio/flex-ui';
import './index.css';
import App from './App';
import AppFlex from './AppFlex';
import registerServiceWorker from './registerServiceWorker';
import * as amc from '@amc-technology/davinci-api';
import { AMCRoot } from './AMCRoot';

const mountNode = document.getElementById('root');
var isAppInitialized = false;
var useAMCUI = true;

window.onload = () => {
  fetch('ClientConfiguration')
    .then(response => response.json())
    .then(serverConfig => {
      if(window.top !== window.self) {
        // This code executes when Twilio Flex App get loaded with in DaVinci UI as an App

        amc.getConfig().then(daVinciAppConfig => {
          const appConfig = {
            daVinciAppConfig,
            serverConfig
          };

          if (
            daVinciAppConfig.variables &&
            daVinciAppConfig.variables.UseAMCUI === false
          ) {
            useAMCUI = false;
          }

          if (!useAMCUI) {
            const flexUIAppHeight = daVinciAppConfig.variables.flexUIAppHeight || 400;
            mountNode.style.setProperty('height', `${flexUIAppHeight}px`);
            amc.setAppHeight(flexUIAppHeight);
          }

          const serviceBaseUrl =
            'https://' + daVinciAppConfig.variables.RuntimeDomain;

          const configuredNotificationSound =
            daVinciAppConfig.variables.AlertingInteractionSound;

          if (configuredNotificationSound.length > 0) {
            localStorage.setItem('SoundIsConfigured', true);
          } else {
            localStorage.setItem('SoundIsConfigured', false);
          }
          generateSoundElement(String(configuredNotificationSound));

          var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
          if (isChrome && configuredNotificationSound.length > 0) {
            localStorage.setItem('isChrome', true);
            localStorage.setItem('SoundNotifications', false);

            //Construct a div for the user to click on yes/no for sound notifications
            var promptUserDiv = document.createElement('div');
            promptUserDiv.id = 'promptUserForSoundDiv';
            promptUserDiv.style = 'background-color: #F4F5FB; height: 24.6px;';

            if (useAMCUI) {
              document.body.appendChild(promptUserDiv);
            } else {
              document.body.prepend(promptUserDiv);
            }

            var promptLabel = document.createElement('label');
            promptLabel.id = 'promptLabelID';
            promptLabel.style = 'margin-left: 10px;';
            promptUserDiv.appendChild(promptLabel);

            var labelText = document.createElement('b');
            labelText.textContent = 'Sound Notifications';
            labelText.style = 'font-size: 0.8em; font-weight: 700; font-family: Arial; color: #333; '
            promptLabel.appendChild(labelText);

            var yesButton = document.createElement('button');
            yesButton.textContent = 'Allow';
            yesButton.style = 'margin-left: 10px; background-color: #1e72ba; border: 0; color: white; height: 70%; width: 60px; font-size: 70%;';
            yesButton.onclick = userSelectedYesSoundNotifications;
            promptLabel.parentNode.insertBefore(yesButton, promptLabel.nextSibling);

            var spaceDiv = document.createElement('div');
            spaceDiv.style = 'height: 5px; background-color: white;';
            spaceDiv.id = 'spaceDiv';
            promptUserDiv.parentNode.insertBefore(spaceDiv, promptUserDiv.nextSibling);
          } else {
            localStorage.setItem('isChrome', false);
          }

          // TODO: Make Flex UI configurable (panel 2 etc.)
          const predefinedConfig = {
            serviceBaseUrl: serviceBaseUrl + '/',
            sso: {
              accountSid: daVinciAppConfig.variables.AccountSID,
              loginPopup: true
            },
            componentProps: {
              AgentDesktopView: {
                showPanel2: false
              }
            }
          };

          const twilioConfiguration = {
            ...predefinedConfig,
          };

          Flex
          .progress(mountNode)
          .provideLoginInfo(twilioConfiguration, mountNode)
          .then(() => Flex.Manager.create(twilioConfiguration))
          .then(manager => renderApp(manager, appConfig))
          .catch(error => handleError(error));
        });
      } else {
        // This code only executes on SSO POPUP Redirect to the Twilio Flex URL
        // Upon authentication, URL gets redirected back to DaVinci Flex App URL
        // and below default code detects the same and does the necessary to notify
        // Twilio Flex App on the Success and closes automatically

        const predefinedConfig = window.appConfig || {};

        // TODO: Make Flex UI configurable (panel 2 etc.)
        const configuration = {
          ...predefinedConfig,
        };

        Flex
          .progress(mountNode)
          .provideLoginInfo(configuration, mountNode)
          .then(() => Flex.Manager.create(configuration))
          .then(manager => renderApp(manager))
          .catch(error => handleError(error));
      }
    });
};

function renderApp(manager, appConfig) {
  if (!useAMCUI) {
    if (!isAppInitialized) {
      isAppInitialized = true;
      new AMCRoot({manager: manager, config: appConfig});
    }

    ReactDOM.render(<AppFlex manager={manager} />, mountNode);
  } else {
    ReactDOM.render(<App manager={manager} config={appConfig} />, mountNode);
  }

}

function generateSoundElement(soundUrl) {
  var audio = document.createElement('audio');
  audio.src = soundUrl;
  audio.id = 'AudioHTML';
  audio.type = 'audio/mpeg';
  audio.addEventListener('ended', function() {
    this.currentTime = 0;
    this.play();
  }, false);
  document.body.appendChild(audio);
}

function handleError(error) {
  console.error('Failed to initialize Flex', error);

  const missingAccountSid =
    error instanceof Flex.ConfigError && error.key === 'accountSid';

  if (!missingAccountSid) {
    throw error;
  }
}

function userSelectedYesSoundNotifications() {
  localStorage.setItem('SoundNotifications', true);

  if (useAMCUI) {
    amc.setAppHeight(10);
  }

  var element = document.getElementById('promptUserForSoundDiv');
  element.parentNode.removeChild(element);
  var spacingElement = document.getElementById('spaceDiv');
  spacingElement.parentNode.removeChild(spacingElement);
}

registerServiceWorker();
