const PostCallSurveyService = {
  surveyInProgress: false,
  surveyTriggeredList: {},

  checkIfSurveyWasTriggered(taskId) {
    if (this.surveyTriggeredList[taskId] == true) {
      return true;
    } else {
      return false;
    }
  },

  addToSurveyList(taskId) {
    this.surveyTriggeredList[taskId] = true;
  },

  setSurveyProgress(progress) {
    this.surveyInProgress = progress;
  },

  isSurveyInProgress() {
    return this.surveyInProgress;
  },

  removeTaskFromSurveyList(taskId) {
    delete this.surveyTriggeredList[taskId];
  },

  async triggerSurveyFunction(taskId, flexToken, surveyURL) {
    async function triggerFunction(url = '', data = {}) {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      return response;
    }
    await triggerFunction(surveyURL, {
      Token: flexToken,
      taskSid: taskId,
    });
  },
};

export default PostCallSurveyService;
