import React from 'react';
import * as ContactCanvasChannelApi from '@amc-technology/davinci-api';
import * as Flex from '@twilio/flex-ui';
import { addContextualContacts, clearContextualContacts, sendNotification, NOTIFICATION_TYPE } from '@amc-technology/davinci-api';
import * as FlexStore from './FlexStore';
import { getContactsId } from './util';
import PostCallSurveyService from './Services/PostCallSurveyService';

export class Scenario extends React.Component {
  constructor(props) {
    super(props);
    this.storeListener = this.storeListener.bind(this);
    this.workerSID = props.workerSID;
    this.flexToken = props.flexToken;
    this.workerURI = props.workerURI;
    this.attributes = props.attributes;
    this.associatedData = props.associatedData;
    this.props = props;
    this.logger = props.logger;
    this.manager = props.manager;
    this.taskId = props.taskId;
    this.taskKey = props.taskKey;
    this.iconPack = props.iconPack;
    this.removeSelf = props.removeSelf;
    this.resize = props.resize;
    this.toggleCallControls = props.toggleCallControls;
    this.holdData = {
      history: [],
      lastHoldStart: null,
    };
    this.messageCount = 0;
    this.status = '';
    this.taskWasTransferredToAgent = false;
    this.enablePostCallSurvey = false;
    this.surveyFunctionURL = '';
    this.config = props.config;
    this.autoAnswerTimer = setTimeout(() => {}, 1000);
    this.autoInTimer = setTimeout(() => {}, 1000);

    if (
      this.props.config.daVinciAppConfig.variables &&
      this.props.config.daVinciAppConfig.variables.UseAMCUI === false
    ) {
      this.useAMCUI = false;

      // Override React functions here
      this.setState = (data) => {
        const fname = 'setState';
        const cname = 'Scenario';

        try {
          this.logger.logTrace(`${cname} - ${fname} - START`);

          for (const key in data) {
            this.logger.logLoop(`${cname} - ${fname} - Processing key ${key}`);

            if (data.hasOwnProperty(key)) {
              // eslint-disable-next-line react/no-direct-mutation-state
              this.state[key] = data[key];
            }
          }
        } catch (e) {
          this.logger.logError(`${cname} - ${fname} - Error: ${JSON.stringify(e)}`);
        } finally {
          this.logger.logTrace(`${cname} - ${fname} - END`);
        }
      }
    } else {
      this.useAMCUI = true;
    }

    /**
     * CallbackDomain is required to call requeue task Twilio service
     * If CallbackDomain does not exist, requeue button will display error
     * Error will tell agent that CallbackDomain is not configured
     *
     * MaxRequeue limits the number of times an agent can re-queue the callback task
     * if MaxRequeue is '0', agent cannot requeue the callback task
     * if MaxRequeue is > 0, agent can requeue task MaxRequeue number of times
     * if MaxRequeue does NOT exist in the config, agent can requeue task without limit
     */
    if (
      props.config.daVinciAppConfig &&
      props.config.daVinciAppConfig.QueuedCallback &&
      props.config.daVinciAppConfig.QueuedCallback.variables
    ) {
      if (
        props.config.daVinciAppConfig.QueuedCallback.variables.CallbackDomain != null &&
        props.config.daVinciAppConfig.QueuedCallback.variables.CallbackDomain
      ) {
        this.callbackServiceUrl = props.config.daVinciAppConfig.QueuedCallback.variables.CallbackDomain;
      }
      if (
        props.config.daVinciAppConfig.QueuedCallback.variables.MaxRequeue != null &&
        props.config.daVinciAppConfig.QueuedCallback.variables.MaxRequeue >= 0
      ) {
        this.maxRequeue = props.config.daVinciAppConfig.QueuedCallback.variables.MaxRequeue;
      }
    }
    if (props.config.daVinciAppConfig && props.config.daVinciAppConfig.variables.EnablePostCallSurvey) {
      this.enablePostCallSurvey = props.config.daVinciAppConfig.variables.EnablePostCallSurvey;
    }
    if (props.config.daVinciAppConfig && props.config.daVinciAppConfig.variables.SurveyFunctionURL) {
      this.surveyFunctionURL = props.config.daVinciAppConfig.variables.SurveyFunctionURL;
    }

    FlexStore.subscribe(this.storeListener);
    this.ref = React.createRef();

    // AMC API interaction
    this.interaction = {
      interactionId: this.taskId,
      scenarioId: this.taskId,
      direction: ContactCanvasChannelApi.INTERACTION_DIRECTION_TYPES.Inbound,
    };

    // UI Lib interaction
    this.state = {
      scenario: {
        interactions: [
          {
            interactionId: this.taskId,
            operations: [],
            associatedData: [],
            displayCallTimer: true,
            startTime: new Date().getTime(),
            subheaderData: {
              image: new URL(this.iconPack + 'Phone_Number_Icon.png'),
              tooltip: '',
              value: '',
            },
            properties: [],
            UIHeadersData: {
              minimizeUrl: new URL(this.iconPack + 'section_collapse.png'),
              maximizeUrl: new URL(this.iconPack + 'section_expand.png'),
              directionText: 'Inbound',
              statusText: 'Ringing',
              statusUrl: new URL(this.iconPack + 'Status_Ringing.png'),
            },
          },
        ],
      },
      visible: true,
    };

    if (!this.useAMCUI) {
      this.componentDidMount();
    }

  }

  async onIsAgentTyping() {
    try {
      const channelSid = this.manager.store.getState().flex.worker.tasks.get(this.taskKey).source.attributes.channelSid;
      await Flex.Actions.invokeAction('SendTyping', {
        channelSid: channelSid,
      });
    } catch (e) {
      this.logger.logError(`Error on send typing: task=${this.taskId} error=${e.message}`);
    }
  }
  async onNewMessage(message) {
    try {
      const channelSid = this.manager.store.getState().flex.worker.tasks.get(this.taskKey).source.attributes.channelSid;
      await Flex.Actions.invokeAction('SendMessage', {
        channelSid: channelSid,
        body: message.detail,
      });
    } catch (e) {
      this.logger.logError(`Error on new message: task=${this.taskId} error=${e.message}`);
    }
  }
  afterWebComponentViewChecked() {
    this.resize();
  }
  async componentDidMount() {
    try {
      this.logger.logDebug(`Scenario componentDidMount: taskId=${this.taskId}`);

      if (this.useAMCUI) {
        this.nv.addEventListener('isAgentTyping', this.onIsAgentTyping.bind(this));
        this.nv.addEventListener('newMessage', this.onNewMessage.bind(this));
        this.nv.addEventListener('afterViewChecked', this.afterWebComponentViewChecked.bind(this));
      }

      const uiInteraction = { ...this.state.scenario.interactions[0] };
      const flexState = this.manager.store.getState();
      const task = flexState.flex.worker.tasks.get(this.taskKey);

      if (task) {
        this.logger.logDebug(`Scenario componentDidMount: taskId=${this.taskId} channelName=${task.source.taskChannelUniqueName}`);

        switch (task.source.taskChannelUniqueName) {
          case 'voice':
            this.interaction.channelType = ContactCanvasChannelApi.CHANNEL_TYPES.Telephony;
            uiInteraction.subheaderData.image = new URL(this.iconPack + 'Phone_Number_Icon.png');
            uiInteraction.subheaderData.tooltip = 'Phone';
            break;
          case 'chat':
          case 'sms':
            if (task.attributes.channelType === 'sms') {
              this.interaction.channelType = ContactCanvasChannelApi.CHANNEL_TYPES.SMS;

              uiInteraction.subheaderData.image = new URL(this.iconPack + 'chat_symbol.png');
              uiInteraction.subheaderData.tooltip = 'SMS';
            } else {
              this.interaction.channelType = ContactCanvasChannelApi.CHANNEL_TYPES.Chat;

              uiInteraction.subheaderData.image = new URL(this.iconPack + 'chat_symbol.png');
              uiInteraction.subheaderData.tooltip = 'Chat';
            }
            break;
          default:
            this.logger.logWarning(
              `Unknown channel, defaulting to phone. taskId=${this.taskId} channelName=${task.source.taskChannelUniqueName}`
            );
            this.interaction.channelType = ContactCanvasChannelApi.CHANNEL_TYPES.Telephony;

            uiInteraction.subheaderData.image = new URL(this.iconPack + 'Phone_Number_Icon.png');
            uiInteraction.subheaderData.tooltip = 'Phone';
        }

        const directionAttribute = task.source.attributes.direction;
        const taskType = task.source.attributes.taskType;

        let placeCallRetry;
        this.logger.logDebug(
          `Scenario componentDidMount checking for placeCallRetry variable: taskId=${this.taskId} channelName=${task.source.taskChannelUniqueName}`
        );
        if (task.source.attributes.placeCallRetry != null && task.source.attributes.placeCallRetry) {
          this.logger.logDebug(
            `Scenario componentDidMount assigning placeCallRetry: taskId=${this.taskId} channelName=${task.source.taskChannelUniqueName}`
          );
          placeCallRetry = task.source.attributes.placeCallRetry;
        }

        if (directionAttribute !== undefined && directionAttribute.toLowerCase() === 'inbound' && taskType !== 'callback') {
          uiInteraction.UIHeadersData.directionText = 'Inbound';
          this.interaction.direction = ContactCanvasChannelApi.INTERACTION_DIRECTION_TYPES.Inbound;
        } else if (directionAttribute !== undefined && directionAttribute.toLowerCase() === 'inbound' && taskType === 'callback') {
          this.logger.logDebug(
            `Scenario componentDidMount callback detected: taskId=${this.taskId} channelName=${task.source.taskChannelUniqueName}`
          );
          if (this.maxRequeue != null && this.maxRequeue > 0) {
            uiInteraction.UIHeadersData.directionText = `Callback ${placeCallRetry} of ${this.maxRequeue}`;
          } else if (this.maxRequeue != null && this.maxRequeue <= 0) {
            uiInteraction.UIHeadersData.directionText = `Callback ${placeCallRetry}`;
          } else {
            uiInteraction.UIHeadersData.directionText = `Callback ${placeCallRetry}`;
          }
          this.interaction.direction = ContactCanvasChannelApi.INTERACTION_DIRECTION_TYPES.Inbound;
        } else if (directionAttribute !== undefined && directionAttribute.toLowerCase() === 'outbound') {
          uiInteraction.UIHeadersData.directionText = 'Outbound';
          this.interaction.direction = ContactCanvasChannelApi.INTERACTION_DIRECTION_TYPES.Outbound;
        } else if (directionAttribute !== undefined && directionAttribute.toLowerCase() === 'internal') {
          uiInteraction.UIHeadersData.directionText = 'Internal';
          this.interaction.direction = ContactCanvasChannelApi.INTERACTION_DIRECTION_TYPES.Internal;
        }

        const identifier =
          task.source.attributes.callback ||
          task.source.attributes.name ||
          task.source.attributes.contact ||
          task.source.attributes.caller ||
          task.source.attributes.outbound_to ||
          task.source.attributes.from;
        uiInteraction.subheaderData.value = identifier;

        const details = new ContactCanvasChannelApi.RecordItem('', '', '');
        if (this.interaction.channelType === ContactCanvasChannelApi.CHANNEL_TYPES.Chat) {
          if (/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(identifier)) {
            // Verify if identifier is a Phone Number
            /*Valid formats:

                        (123) 456-7890
                        (123)456-7890
                        123-456-7890
                        123.456.7890
                        1234567890
                        +31636363634
                        075-63546725
                    */
            details.setPhone('', '', identifier);
          } else if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(identifier)) {
            // Verify if identifier is an Email address
            /*It allows the following formats:

                    1.  prettyandsimple@example.com
                    2.  very.common@example.com
                    3.  disposable.style.email.with+symbol@example.com
                    4.  other.email-with-dash@example.com
                    9.  #!$%&'*+-/=?^_`{}|~@example.org
                    6.  "()[]:,;@\\\"!#$%&'*+-/=?^_`{}| ~.a"@example.org
                    7.  " "@example.org (space between the quotes)
                    8.  üñîçøðé@example.com (Unicode characters in local part)
                    9.  üñîçøðé@üñîçøðé.com (Unicode characters in domain part)
                    10. Pelé@example.com (Latin)
                    11. δοκιμή@παράδειγμα.δοκιμή (Greek)
                    12. 我買@屋企.香港 (Chinese)
                    13. 甲斐@黒川.日本 (Japanese)
                    14. чебурашка@ящик-с-апельсинами.рф (Cyrillic)
                    */

            details.setEmail('', '', identifier);
          } else {
            // Else assume identifier as a Full Name
            details.setFullName('', '', identifier);
          }
        } else {
          details.setPhone('', '', identifier);
        }
        if (task.source.attributes) {
          for (const field in task.source.attributes) {
            details.setField(field, '', '', task.source.attributes[field]);
          }
        }
        this.interaction.details = details;
        if (this.associatedData) {
          uiInteraction.associatedData = this.associatedData;
        }

        this.setState({
          scenario: {
            interactions: [uiInteraction],
          },
        });

        await this.processTaskStatus(task.source.status);
      }

      this.resize();
    } catch (e) {
      this.logger.logError(`Error componentDidMount: task=${this.taskId} error=${e.message}`);
    }
  }

  updateMessage(state) {
    try {
      this.logger.logDebug(`updateMessage taskId=${this.taskId}`);
      const channelSid = state.flex.worker.tasks.get(this.taskKey).source.attributes.channelSid;
      const channel = state.flex.chat.channels[channelSid];
      const twilioMessages = channel.messages;
      const myself = channel.source.services.users.myself.state.identity;
      if (twilioMessages.length > this.messageCount) {
        this.logger.logDebug(`updateMessage. new message! taskId=${this.taskId}`);
        this.messageCount = twilioMessages.length;
        const messages = twilioMessages.map((message) => {
          let user = Object.values(state.flex.chat.users).find((user) => user.identity === message.source.state.author);
          if (user === undefined) {
            user = {
              friendlyName: message.source.state.author,
              identity: message.source.state.author,
            };
          }
          return {
            username: user.friendlyName,
            text: message.source.state.body,
            type: myself === user.identity ? 'AGENT' : undefined,
          };
        });
        {
          const interaction = { ...state.scenario.interactions[0] };
          if (!interaction.chat) {
            interaction.chat = {
              settings: {
                sendImage: new URL(this.iconPack + 'request_send.png'),
                maxHeight: '300px',
              },
              messages: [],
              isCustomerTyping: false,
            };
          }
          interaction.chat.messages = messages;
          this.setState({ scenario: { interactions: [interaction] } });
        }
      }
    } catch (e) {
      this.logger.logError(`Error updateMessage: task=${this.taskId} error=${e.message}`);
    }
  }
  updateIsTyping(state) {
    try {
      const channelSid = state.flex.worker.tasks.get(this.taskKey).source.attributes.channelSid;
      const channel = state.flex.chat.channels[channelSid];
      const myself = channel.source.services.users.myself.state.identity;
      let someoneElseIsTyping = false;
      channel.members.forEach((user, identity) => {
        const isTyping = user.source.state.isTyping;
        if (isTyping && myself !== identity) {
          someoneElseIsTyping = true;
        }
      });
      if (this.state.scenario.interactions[0].chat && this.state.scenario.interactions[0].chat.isCustomerTyping !== someoneElseIsTyping) {
        this.logger.logDebug(`isCustomerTyping=${someoneElseIsTyping} taskId=${this.taskId}`);

        {
          const interaction = { ...state.scenario.interactions[0] };
          if (!interaction.chat) {
            interaction.chat = {
              settings: {
                sendImage: new URL(this.iconPack + 'request_send.png'),
                maxHeight: '300px',
              },
              messages: [],
              isCustomerTyping: false,
            };
          }
          interaction.chat.isCustomerTyping = someoneElseIsTyping;
          this.setState({ scenario: { interactions: [interaction] } });
        }
      }
    } catch (e) {
      this.logger.logError(`Error updateIsTyping: task=${this.taskId} error=${e.message}`);
    }
  }
  shouldComponentUpdate(nextProps, nextState) {
    this.nv.scenario = nextState.scenario;
    return false;
  }
  componentWillUnmount() {
    this.logger.logDebug(`componentWillUnmount taskId=${this.taskId}`);

    if (this.useAMCUI) {
      this.nv.removeEventListener('minimizedChanged', this.onMinimizedChanged);
      this.nv.removeEventListener('isAgentTyping', this.onIsAgentTyping);
      this.nv.removeEventListener('newMessage', this.onNewMessage);
    }

    FlexStore.unsubscribe(this.storeListener);
  }
  async setInteractionState(state) {
    const functionName = 'setInteractionState';
    try {
      if (this.interaction.state !== state && this.interaction.state !== ContactCanvasChannelApi.INTERACTION_STATES.Disconnected) {
        this.logger.logDebug(`setInteraction taskId=${this.taskId} state=${state}`);
        this.interaction.state = state;

        this.interaction.details.setField('TwilioFlexworkerSID', 'TwilioFlexworkerSID', 'TwilioFlexworkerSID', this.workerSID);
        this.interaction.details.setField('TwilioFlexToken', 'TwilioFlexToken', 'TwilioFlexToken', this.flexToken);
        this.interaction.details.setField('TwilioFlexworkerURI', 'TwilioFlexworkerURI', 'TwilioFlexworkerURI', this.workerURI);

        ContactCanvasChannelApi.setInteraction(this.interaction);

        if (state === ContactCanvasChannelApi.INTERACTION_STATES.Disconnected) {
          if (
            this.attributes.PCS &&
            (this.attributes.PCS === 'voice' || this.attributes.PCS === 'SMS') &&
            this.enablePostCallSurvey === true
          ) {
            try {
              if (this.surveyFunctionURL === '') {
                throw new Error('The Survey Function URL was not provided');
              }
              //Custom Enhancement - Post Call Survey
              //Trigger a Post Call Survey based on a Presidio custom enhancement.
              //Can be disabled via the enablePostCallSurvey configuration in Creator's Studio
              if (window.localStorage.getItem('lastSurveyTask') !== this.taskId) {
                this.logger.logDebug(`${functionName} : Beginning Post Call Survey Function for task ${this.taskId}`);
                window.localStorage.setItem('lastSurveyTask', this.taskId);
                await PostCallSurveyService.triggerSurveyFunction(this.taskId, this.flexToken, this.surveyFunctionURL);
                this.logger.logDebug(`${functionName} : Post Call Survey Complete for task ${this.taskId}`);
              }
            } catch (error) {
              this.logger.logError(
                `${functionName} : Post Call Survey Failed for task ${this.taskId} Error Message: ${error.message || JSON.stringify(error)}`
              );
            }
          }

          var myAudio = document.getElementById('AudioHTML');
          if (!myAudio.paused || myAudio.currentTime) {
            myAudio.pause();
            myAudio.currentTime = 0;
          }
          this.setState({ visible: false });
          this.removeSelf(this.taskWasTransferredToAgent);
        }
      }
    } catch (e) {
      this.logger.logError(`Error setInteractionState: task=${this.taskId} state=${state} error=${e.message}`);
    }
  }

  async completeTask() {
    try {
      this.logger.logDebug(`completing task: taskId=${this.taskId}`);

      const task = this.manager.store.getState().flex.worker.tasks.get(this.taskKey);

      if (task) {
        await Flex.Actions.invokeAction('CompleteTask', {
          sid: this.taskKey,
          reason: 'TaskComplete',
        });
      } else {
        this.logger.logWarning(`Task with taskKey: ${this.taskKey} and taskId: ${this.taskId} is undefined, cannot complete this task in this state`);
      }
    } catch (e) {
      this.logger.logError(`Error completeTask: task=${this.taskId} error=${e.message}`);
    }
  }

  async processTaskStatus(status) {
    try {
      let callback = false;
      let showRequeueBtn = true;
      this.logger.logDebug(`Scenario processTaskStatus - checking if task is callback task: taskId=${this.taskId}`);
      if (this.interaction.details.fields.callback != null && this.interaction.details.fields.callback.Value) {
        callback = true;
        this.logger.logDebug(`Scenario processTaskStatus - task is callback task: taskId=${this.taskId} callback=${callback}`);
      }

      this.logger.logDebug(`Scenario processTaskStatus - checking if placeCallRetry has reached maxRequeue limit: taskId=${this.taskId}`);

      if (
        this.interaction.details.fields.placeCallRetry != null &&
        this.interaction.details.fields.placeCallRetry.Value >= this.maxRequeue
      ) {
        showRequeueBtn = false;
        this.logger.logDebug(
          `Scenario processTaskStatus - placeCallRetry has reached maxRequeue. requeue disabled: taskId=${this.taskId} showRequeueBtn=${showRequeueBtn}`
        );
      }
      if (status !== this.status) {
        this.status = status;
        this.logger.logDebug(`processTaskStatus taskId=${this.taskId} status=${status}`);
        switch (status) {
          case 'wrapping': {
            this.logger.logDebug(`Scenario processTaskStatus - status is wrapping: taskId=${this.taskId} status=${status}`);
            const interaction = { ...this.state.scenario.interactions[0] };
            interaction.UIHeadersData.statusText = 'Wrap up';
            interaction.UIHeadersData.statusUrl = new URL(this.iconPack + 'Status_TwilioFlex_WrapUp.png');
            if (interaction.chat) {
              interaction.chat = {
                settings: {
                  sendImage: new URL(this.iconPack + 'request_send.png'),
                  disableSendMessage: true,
                },
                messages: interaction.chat.messages,
                isCustomerTyping: false,
              };
            }
            if (
              this.config.daVinciAppConfig.CallControls &&
              this.config.daVinciAppConfig.CallControls.variables.AutoIn === true &&
              !callback
            ) {
              let delayLength = 1500;
              if (this.config.daVinciAppConfig.CallControls.variables.AutoInDelay >= 0) {
                this.logger.logDebug(`autoIn operation delay: taskId=${this.taskId}`);
                delayLength = this.config.daVinciAppConfig.CallControls.variables.AutoInDelay;
              }
              clearTimeout(this.autoInTimer);
              this.autoInTimer = setTimeout(() => {
                this.autoIn();
              }, delayLength); // after the delay calls autoIn
            } else if (
              this.config.daVinciAppConfig.QueuedCallback &&
              this.config.daVinciAppConfig.QueuedCallback.variables.AutoIn === true &&
              callback
            ) {
              let delayLength = 15000;
              if (this.config.daVinciAppConfig.QueuedCallback.variables.AutoInDelay >= 0) {
                this.logger.logDebug(`autoIn callback operation delay: taskId=${this.taskId}`);
                delayLength = this.config.daVinciAppConfig.QueuedCallback.variables.AutoInDelay;
              }
              clearTimeout(this.autoInTimer);
              this.autoInTimer = setTimeout(() => {
                this.autoIn();
              }, delayLength); // after the delay calls autoIn
            }
            interaction.operations = [this.wrapUpOperation()];
            this.logger.logDebug(
              `Scenario processTaskStatus - wrapping - checking if task is callback task and showRequeueBtn is TRUE: taskId=${this.taskId} callback=${callback} showRequeueBtn=${showRequeueBtn}`
            );
            if (callback && showRequeueBtn) {
              this.logger.logDebug(
                `Scenario processTaskStatus - wrapping - task is callback and showRequeueBtn is TRUE: taskId=${this.taskId} callback=${callback} showRequeueBtn=${showRequeueBtn}`
              );
              interaction.operations.push(this.requeueTaskOperation());
            }

            // if this call came from a callback task, wrap the callback also
            this.logger.logDebug(`Scenario processTaskStatus - checking if call originated from callback task: taskId=${this.taskId}`);

            if (this.interaction.details.fields.callbackSID &&
              this.interaction.details.fields.callbackSID.Value != null &&
              this.interaction.details.fields.callbackSID.Value) {
              this.logger.logDebug(`Scenario processTaskStatus - call originated from callback. Wrapping callback task: taskId=${this.taskId}`);

              await Flex.Actions.invokeAction('WrapupTask', {
                sid: this.interaction.details.fields.callbackSID.Value,
              }).catch((error) => {
                this.logger.logDebug('Error in processTaskStatus, failure in action callback WrapupTask : ' + error.toString());
              });
            }

            this.setState({ scenario: { interactions: [interaction] } });

            break;
          }
          case 'pending': {
            break;
          }
          case 'reserved': {
            this.logger.logDebug(`Scenario processTaskStatus - status is reserved: taskId=${this.taskId} status=${status}`);
            this.setInteractionState(ContactCanvasChannelApi.INTERACTION_STATES.Alerting);
            if (
              (localStorage.getItem('isChrome') === 'false' ||
                (localStorage.getItem('isChrome') === 'true' && localStorage.getItem('SoundNotifications') === 'true')) &&
              localStorage.getItem('SoundIsConfigured') === 'true'
            ) {
              this.playAudioAlert();
            }
            const interaction = { ...this.state.scenario.interactions[0] };
            interaction.UIHeadersData.statusText = 'Alerting';
            interaction.UIHeadersData.statusUrl = new URL(this.iconPack + 'Status_Ringing.png');
            if (this.config.daVinciAppConfig.CallControls && this.config.daVinciAppConfig.CallControls.variables.AutoAnswer === true) {
              let delayLength = 1500;
              if (this.config.daVinciAppConfig.CallControls.variables.AutoAnswerDelay >= 0) {
                this.logger.logDebug(`autoAnswerOperation delay=${this.taskId}`);
                delayLength = this.config.daVinciAppConfig.CallControls.variables.AutoAnswerDelay;
              }
              clearTimeout(this.autoAnswerTimer);
              this.autoAnswerTimer = setTimeout(this.autoAnswer.bind(this), delayLength); // after the delay calls autoAnswer
            }

            let interactionOperationsArray = [];
            if (interaction.UIHeadersData.directionText !== 'Outbound') {
              this.logger.logDebug(
                `Scenario processTaskStatus - reserved - checking if task is callback: taskId=${this.taskId} callback=${callback}`
              );
              if (callback) {
                this.logger.logDebug(
                  `Scenario processTaskStatus - reserved - task is callback. push acceptCallbackOperation: taskId=${this.taskId} callback=${callback}`
                );
                interactionOperationsArray.push(this.acceptCallbackOperation());
              } else {
                this.logger.logDebug(
                  `Scenario processTaskStatus - reserved - task is not callback. push answerOperation: taskId=${this.taskId} callback=${callback}`
                );
                interactionOperationsArray.push(this.answerOperation());
              }
            }
            if (interaction.UIHeadersData.directionText === 'Outbound') {
              interactionOperationsArray.push(this.endOperation());
            }
            if (this.config.daVinciAppConfig.CallControls) {
              if (
                this.config.daVinciAppConfig.CallControls.variables.RejectButtonEnabled === true &&
                interaction.UIHeadersData.directionText !== 'Outbound'
              ) {
                if (callback) {
                  this.logger.logDebug(
                    `Scenario processTaskStatus - reserved - task is callback. push rejectCallbackOperation: taskId=${this.taskId} callback=${callback}`
                  );
                  interactionOperationsArray.push(this.rejectCallbackOperation());
                } else {
                  this.logger.logDebug(
                    `Scenario processTaskStatus - reserverd - task is not callback. push rejectOperation: taskId=${this.taskId} callback=${callback}`
                  );
                  interactionOperationsArray.push(this.rejectOperation());
                }
              }
            } else {
              // if the new configs aren't added the operation must be enabled
              if (interaction.UIHeadersData.directionText !== 'Outbound') {
                if (callback) {
                  this.logger.logDebug(
                    `Scenario processTaskStatus - reserved - no CallControl detected. task is callback. push rejectCallbackOpersation: taskId=${this.taskId} callback=${callback}`
                  );
                  interactionOperationsArray.push(this.rejectCallbackOperation());
                } else {
                  this.logger.logDebug(
                    `Scenario processTaskStatus - reserved - no CallControl detected. task is not callback. push rejectOperation: taskId=${this.taskId} callback=${callback}`
                  );
                  interactionOperationsArray.push(this.rejectOperation());
                }
              }
            }

            interaction.operations = interactionOperationsArray;
            this.setState({ scenario: { interactions: [interaction] } });

            break;
          }
          case 'assigned':
          case 'accepted': {
            this.logger.logDebug(`Scenario processTaskStatus - status is accepted: taskId=${this.taskId} status=${status}`);
            this.setInteractionState(ContactCanvasChannelApi.INTERACTION_STATES.Connected);
            this.stopAudioAlert();
            const interaction = { ...this.state.scenario.interactions[0] };
              interaction.UIHeadersData.statusText = 'Connected';
              interaction.UIHeadersData.statusUrl = new URL(this.iconPack + 'Status_OnCall.png');
              if (this.interaction.channelType === ContactCanvasChannelApi.CHANNEL_TYPES.Telephony) {
                let interactionOperationsArray = [];
                if (!callback) {
                  this.logger.logDebug(
                    `Scenario processTaskStatus - accepted - task not callback. push endOperation: taskId=${this.taskId} callback=${callback}`
                  );
                  interactionOperationsArray.push(this.endOperation());
                }
                if (this.config.daVinciAppConfig.CallControls) {
                  if (this.config.daVinciAppConfig.CallControls.variables.HoldButtonEnabled === true && !callback) {
                    // adding Hold Operation to list of Operations
                    this.logger.logDebug(
                      `Scenario processTaskStatus - accepted - task not callback and HoldButtonEnabled is TRUE: taskId=${this.taskId} callback=${callback}`
                    );
                    interactionOperationsArray.push(this.holdAudioOperation());
                  }
                  if (
                    this.config.daVinciAppConfig.CallControls.variables.TransferAgentButtonEnabled === true &&
                    interaction.UIHeadersData.directionText !== 'Outbound' &&
                    !callback
                  ) {
                    // adding agent transfer operation to list of operations
                    this.logger.logDebug(
                      `Scenario processTaskStatus - accepted - task not callback and TransferAgentButtonEnabled is TRUE and not outbound call: taskId=${this.taskId} callback=${callback} directionText=${interaction.UIHeadersData.directionText}`
                    );
                    interactionOperationsArray.push(this.agentTransferOperation());
                  }
                  if (
                    this.config.daVinciAppConfig.CallControls.variables.TransferQueueButtonEnabled === true &&
                    interaction.UIHeadersData.directionText !== 'Outbound' &&
                    !callback
                  ) {
                    // adding queue transfer to list of operations
                    this.logger.logDebug(
                      `Scenario processTaskStatus - accepted - task not callback and TransferQueueButtonEnabled is TRUE and not outbound call: taskId=${this.taskId} callback=${callback} directionText=${interaction.UIHeadersData.directionText}`
                    );
                    interactionOperationsArray.push(this.queueTransferOperation());
                  }
                  if (this.config.daVinciAppConfig.CallControls.variables.DTMFButtonEnabled === true && !callback) {
                    // adding Hold Operation to list of Operations
                    this.logger.logDebug(
                      `Scenario processTaskStatus - accepted - task not callback and DTMFButtonEnabled is TRUE: taskId=${this.taskId} callback=${callback}`
                    );
                    interactionOperationsArray.push(this.playDTMFOperation());
                  }
                } else {
                  // adding all operations since if the using the older configs
                  if (!callback) {
                    this.logger.logDebug(
                      `Scenario processTaskStatus - accepted - no CallControl detected. task is not callback: taskId=${this.taskId} callback=${callback} directionText=${interaction.UIHeadersData.directionText}`
                    );
                    interactionOperationsArray.push(this.holdAudioOperation());
                    if (interaction.UIHeadersData.directionText !== 'Outbound') {
                      interactionOperationsArray.push(this.agentTransferOperation());
                      interactionOperationsArray.push(this.queueTransferOperation());
                    }
                    interactionOperationsArray.push(this.playDTMFOperation());
                  }
                }
                interaction.operations = interactionOperationsArray;
              } else {
                if (!interaction.chat) {
                  interaction.chat = {
                    settings: {
                      sendImage: new URL(this.iconPack + 'request_send.png'),
                      maxHeight: '300px',
                    },
                    messages: [],
                    isCustomerTyping: false,
                  };
                }
                interaction.operations = [this.endOperation()];
              }

              this.setState({ scenario: { interactions: [interaction] } });
            break;
          }
          default:
            this.setInteractionState(ContactCanvasChannelApi.INTERACTION_STATES.Disconnected);
        }
      }
    } catch (e) {
      this.logger.logError(`Error processTaskStatus: task=${this.taskId} status=${status} error=${e.message}`);
    }
  }
  async autoAnswer() {
    try {
      this.logger.logDebug(`autoAnswerOperation taskId=${this.taskId}`);

      const task = this.manager.store.getState().flex.worker.tasks.get(this.taskKey);

      if (task) {
        this.manager.voiceClient.incoming(function (connection) {
          connection.accept();
        });

        await Flex.Actions.invokeAction('AcceptTask', {
          sid: this.taskKey,
        }).catch((error) => {
          if (error !== 'Action is pending' && error !== 'Action has been blocked' && error.message !== 'Action is blocked') {
            sendNotification('Auto Answer failed.', NOTIFICATION_TYPE.Error);
          }
          this.logger.logDebug('Error in auto answerOperation, failure in action AcceptTask : ' + error.toString());
        });
      } else {
        this.logger.logWarning(`Task with taskKey: ${this.taskKey} and taskId: ${this.taskId} is undefined, cannot auto-accept this task in this state`);
      }
    } catch (e) {
      this.logger.logError(`Error auto answerOperation: task=${this.taskId} error=${e.message}`);
    }
    try {
      // If autoAnswer on callback task, create outbound call
      this.logger.logDebug(`autoAnswerOperation. StartOutboundCall Attempt : taskId=${this.taskId}`);
      let callbackSid = this.taskKey;
      if (this.interaction.details.fields.callback && this.interaction.details.fields.callback.Value != null) {
        let callbackNumber = this.interaction.details.fields.callback.Value;
        await Flex.Actions.invokeAction('StartOutboundCall', {
          destination: callbackNumber,
          taskAttributes: {
            callbackSID: callbackSid,
          },
        });
        this.logger.logDebug(`autoAnswerOperation. StartOutboundCall Complete : taskId=${this.taskId}`);
      }
    } catch (e) {
      this.logger.logError(`Error autoAnswer Operation: StartOutboundCall: task=${this.taskId} error=${e.message}`);
    }
  }

  autoIn() {
    try {
      this.logger.logDebug(`autoIn wrapUpOperation taskId=${this.taskId}`);
      this.completeTask();
      this.setInteractionState(ContactCanvasChannelApi.INTERACTION_STATES.Disconnected);
    } catch (e) {
      sendNotification('Wrap-Up failed.', NOTIFICATION_TYPE.Error);
      this.logger.logError(`Error wrapUpOperation: task=${this.taskId} error=${e.message}`);
    }
  }

  async storeListener() {
    try {
      const state = this.manager.store.getState();

      const task = state.flex.worker.tasks.get(this.taskKey);
      if (task) {
        await this.processTaskStatus(task.status);

        if (Object.keys(state.flex.chat.channels).length > 0) {
          this.updateMessage(state);
          this.updateIsTyping(state);
        }
      } else {
        // if this call came from a callback task, wrap the callback also
        this.logger.logDebug(`Scenario processTaskStatus - checking if call originated from callback task: taskId=${this.taskId}`);

        if (this.interaction.details.fields.callbackSID &&
          this.interaction.details.fields.callbackSID.Value != null &&
          this.interaction.details.fields.callbackSID.Value) {
          this.logger.logDebug(`Scenario processTaskStatus - call originated from callback. Wrapping callback task: taskId=${this.taskId}`);

          await Flex.Actions.invokeAction('WrapupTask', {
            sid: this.interaction.details.fields.callbackSID.Value,
          }).catch((error) => {
            this.logger.logDebug('Error in processTaskStatus, failure in action callback WrapupTask : ' + error.toString());
          });
        }

        this.setInteractionState(ContactCanvasChannelApi.INTERACTION_STATES.Disconnected);
      }
    } catch (e) {
      this.logger.logError(`Error scenario storeListener: task=${this.taskId} error=${e.message}`);
    }
  }
  holdAudioOperation() {
    try {
      return {
        operationName: 'Hold',
        icon: new URL(this.iconPack + 'voice_hold_normal.png'),
        title: 'Hold',
        handler: async (operationName, operationMetadata) => {
          try {
            this.logger.logDebug(`holdAudioOperation taskId=${this.taskId}`);
            await Flex.Actions.invokeAction('HoldCall', {
              sid: this.taskKey,
            }).catch((error) => {
              if (error !== 'Action is pending') {
                sendNotification('Hold operation failed.', NOTIFICATION_TYPE.Error);
              }
              this.logger.logDebug('Error in holdAudioOperation, failure in action HoldCall : ' + error.toString());
            });

            if (!this.holdData.lastHoldStart) {
              const startTime = new Date().valueOf();
              this.holdData.lastHoldStart = startTime;

              const interaction = { ...this.state.scenario.interactions[0] };
              interaction.UIHeadersData.statusText = 'On Hold';
              interaction.UIHeadersData.statusUrl = new URL(this.iconPack + 'Status_OnHold.png');
              interaction.operations = [this.resumeAudioOperation()];
              interaction.holdCounterData = {
                pastCallDurations: this.holdData.history,
                currentHoldStartTime: startTime,
              };

              this.setState({ scenario: { interactions: [interaction] } });
            }
          } catch (e) {
            this.logger.logError(`Error holdAudioOperation: task=${this.taskId} error=${e.message}`);
          }
        },
      };
    } catch (e) {
      this.logger.logError(`Error creating holdAudioOperation: task=${this.taskId} error=${e.message}`);
    }
  }
  resumeAudioOperation() {
    try {
      return {
        operationName: 'Resume',
        icon: new URL(this.iconPack + 'voice_unhold_normal.png'),
        title: 'Resume',
        handler: async (operationName, operationMetadata) => {
          try {
            this.logger.logDebug(`resumeAudioOperation taskId=${this.taskId}`);
            await Flex.Actions.invokeAction('UnholdCall', {
              sid: this.taskKey,
            }).catch((error) => {
              if (error !== 'Action is pending') {
                sendNotification('Unhold operation failed.', NOTIFICATION_TYPE.Error);
              }
              this.logger.logDebug('Error in resumeAudioOperation, failure in action UnholdCall : ' + error.toString());
            });

            if (this.holdData.lastHoldStart) {
              const endTime = new Date().valueOf();
              this.holdData.history.push({
                startTime: this.holdData.lastHoldStart,
                endTime: endTime,
              });
              this.holdData.lastHoldStart = null;

              const interaction = { ...this.state.scenario.interactions[0] };
              interaction.UIHeadersData.statusText = 'Connected';
              interaction.UIHeadersData.statusUrl = new URL(this.iconPack + 'Status_OnCall.png');
              if (interaction.UIHeadersData.directionText === 'Outbound') {
                interaction.operations = [this.endOperation(), this.holdAudioOperation()];
              } else {
                interaction.operations = [
                  this.endOperation(),
                  this.holdAudioOperation(),
                  this.agentTransferOperation(),
                  this.queueTransferOperation(),
                ];
              }

              this.setState({ scenario: { interactions: [interaction] } });

            }
          } catch (e) {
            this.logger.logError(`Error resumeAudioOperation: task=${this.taskId} error=${e.message}`);
          }
        },
      };
    } catch (e) {
      this.logger.logError(`Error creating resumeAudioOperation: task=${this.taskId} error=${e.message}`);
    }
  }
  acceptCallbackOperation() {
    try {
      return {
        operationName: 'Accept',
        operationMetadata: [
          {
            key: 'this',
            value: this,
          },
        ],
        icon:
          this.interaction.channelType === ContactCanvasChannelApi.CHANNEL_TYPES.Telephony
            ? new URL(this.iconPack + 'Accept_Callback_Twilioflex.png')
            : new URL(this.iconPack + 'chat_check_normal.gif'),
        title: 'Accept',
        handler: async (operationName, operationMetadata) => {
          try {
            clearTimeout(this.autoAnswerTimer);
            this.logger.logDebug(`acceptCallbackOperation taskId=${this.taskId}`);
            await Flex.Actions.invokeAction('AcceptTask', {
              sid: this.taskKey,
            }).catch((error) => {
              if (error !== 'Action is pending' && error !== 'Action has been blocked' && error.message !== 'Action is blocked') {
                sendNotification('Accept failed.', NOTIFICATION_TYPE.Error);
              }
              this.logger.logDebug('Error in acceptCallbackOperation, failure in action AcceptTask : ' + error.toString());
            });
          } catch (e) {
            this.logger.logError(`Error acceptCallbackOperation AcceptTask: taskId=${this.taskId} error=${e.message}`);
          }
          try {
            let callbackSid = this.taskKey;
            let callbackNumber = this.interaction.details.fields.callback.Value;
            await Flex.Actions.invokeAction('StartOutboundCall', {
              destination: callbackNumber,
              taskAttributes: {
                callbackSID: callbackSid,
              },
            }).catch((error) => {
              sendNotification('Outbound Call failed.', NOTIFICATION_TYPE.Error);
              this.logger.logDebug('Error in acceptCallbackOperation, failure in action StartOutboundCall : ' + error.toString());
            });
          } catch (e) {
            this.logger.logError(`Error acceptCallbackOperation StartOutboundCall: taskId=${this.taskId} error=${e.message}`);
          }
        },
      };
    } catch (e) {
      this.logger.logError(`Error creating acceptCallbackOperation: taskId=${this.taskId} error=${e.message}`);
    }
  }
  rejectCallbackOperation() {
    try {
      return {
        operationName: 'Reject',
        icon:
          this.interaction.channelType === ContactCanvasChannelApi.CHANNEL_TYPES.Telephony
            ? new URL(this.iconPack + 'Reject_Callback_Twilioflex.png')
            : new URL(this.iconPack + 'chat_end_normal.png'),
        title: 'Reject',
        handler: async (operationName, operationMetadata) => {
          try {
            this.stopAudioAlert();
            this.logger.logDebug(`rejectCallbackOperation taskId=${this.taskId}`);
            await Flex.Actions.invokeAction('RejectTask', {
              sid: this.taskKey,
            }).catch((error) => {
              if (error !== 'Action is pending') {
                sendNotification('Reject failed.', NOTIFICATION_TYPE.Error);
              }
              this.logger.logDebug('Error in rejectCallbackOperation, failure in action RejectTask : ' + error.toString());
            });
          } catch (e) {
            this.logger.logError(`Error rejectCallbackOperation: task=${this.taskId} error=${e.message}`);
          }
        },
      };
    } catch (e) {
      this.logger.logError(`Error creating rejectOperation: task=${this.taskId} error=${e.message}`);
    }
  }
  answerOperation() {
    try {
      return {
        operationName: 'Answer',
        operationMetadata: [
          {
            key: 'this',
            value: this,
          },
        ],
        icon:
          this.interaction.channelType === ContactCanvasChannelApi.CHANNEL_TYPES.Telephony
            ? new URL(this.iconPack + 'voice_alerting_answer_normal.gif')
            : new URL(this.iconPack + 'chat_check_normal.gif'),
        title: 'Answer',
        handler: async (operationName, operationMetadata) => {
          try {
            clearTimeout(this.autoAnswerTimer);
            this.logger.logDebug(`answerOperation taskId=${this.taskId}`);
            this.manager.voiceClient.incoming(function (connection) {
              connection.accept();
            });

            await Flex.Actions.invokeAction('AcceptTask', {
              sid: this.taskKey,
            }).catch((error) => {
              if (error !== 'Action is pending' && error !== 'Action has been blocked' && error.message !== 'Action is blocked') {
                sendNotification('Answer failed.', NOTIFICATION_TYPE.Error);
              }
              this.logger.logDebug('Error in answerOperation, failure in action AcceptTask : ' + error.toString());
            });
          } catch (e) {
            this.logger.logError(`Error answerOperation: task=${this.taskId} error=${e.message}`);
          }
        },
      };
    } catch (e) {
      this.logger.logError(`Error creating answerOperation: task=${this.taskId} error=${e.message}`);
    }
  }
  rejectOperation() {
    try {
      return {
        operationName: 'Reject',
        icon:
          this.interaction.channelType === ContactCanvasChannelApi.CHANNEL_TYPES.Telephony
            ? new URL(this.iconPack + 'voice_end_normal.png')
            : new URL(this.iconPack + 'chat_end_normal.png'),
        title: 'Reject',
        handler: async (operationName, operationMetadata) => {
          try {
            this.stopAudioAlert();
            this.logger.logDebug(`rejectOperation taskId=${this.taskId}`);
            await Flex.Actions.invokeAction('RejectTask', {
              sid: this.taskKey,
            }).catch((error) => {
              if (error !== 'Action is pending') {
                sendNotification('Reject failed.', NOTIFICATION_TYPE.Error);
              }
              this.logger.logDebug('Error in rejectOperation, failure in action RejectTask : ' + error.toString());
            });
          } catch (e) {
            this.logger.logError(`Error rejectOperation: task=${this.taskId} error=${e.message}`);
          }
        },
      };
    } catch (e) {
      this.logger.logError(`Error creating rejectOperation: task=${this.taskId} error=${e.message}`);
    }
  }
  stopAudioAlert() {
    try {
      var myAlert = document.getElementById('AudioHTML');
      myAlert.pause();
      myAlert.currentTime = 0;
    } catch (e) {
      this.logger.logError(`Scenario.jsx, StopAudioAler, error=${e.message}`);
    }
  }

  playAudioAlert() {
    try {
      var myAlert = document.getElementById('AudioHTML');
      myAlert.play();
    } catch (e) {
      this.logger.logError(`Scenario.jsx, playAudioAlert, error=${e.message}`);
    }
  }
  endOperation() {
    const functionName = 'endOperation';
    try {
      return {
        operationName: 'End',
        icon:
          this.interaction.channelType === ContactCanvasChannelApi.CHANNEL_TYPES.Telephony
            ? new URL(this.iconPack + 'voice_end_normal.png')
            : new URL(this.iconPack + 'chat_end_normal.png'),
        title: 'End',
        handler: async (operationName, operationMetadata) => {
          try {
            this.logger.logDebug(`endOperation taskId=${this.taskId}`);
            if (this.interaction.channelType === ContactCanvasChannelApi.CHANNEL_TYPES.Telephony) {
              if (
                this.attributes.PCS &&
                (this.attributes.PCS === 'voice' || this.attributes.PCS === 'SMS') &&
                this.enablePostCallSurvey === true
              ) {
                try {
                  if (this.surveyFunctionURL === '') {
                    throw new Error('The Survey Function URL was not provided');
                  }
                  //Custom Enhancement - Post Call Survey
                  // Trigger a Post Call Survey based on a Presidio custom enhancement.
                  //Can be disabled via the enablePostCallSurvey configuration in Creator's Studio
                  if (window.localStorage.getItem('lastSurveyTask') !== this.taskId) {
                    this.logger.logDebug(`${functionName} : Beginning Post Call Survey Function for task ${this.taskId}`);
                    window.localStorage.setItem('lastSurveyTask', this.taskId);
                    await PostCallSurveyService.triggerSurveyFunction(this.taskId, this.flexToken, this.surveyFunctionURL);
                    this.logger.logDebug(`${functionName} : Post Call Survey Complete for task ${this.taskId}`);
                  }
                } catch (error) {
                  this.logger.logError(
                    `${functionName} : Post Call Survey Failed for task ${this.taskId} Error Message: ${
                      error.message || JSON.stringify(error)
                    }`
                  );
                }
              }
              await Flex.Actions.invokeAction('HangupCall', {
                sid: this.taskKey,
              }).catch((error) => {
                if (error !== 'Action is pending') {
                  sendNotification('End phone call failed.', NOTIFICATION_TYPE.Error);
                }
                this.logger.logDebug('Error in endOperation, failure in action HangupCall: ' + error.toString());
              });
            }
            await Flex.Actions.invokeAction('WrapupTask', {
              sid: this.taskKey,
            }).catch((error) => {
              this.logger.logDebug('Error in endOperation, failure in action WrapupTask : ' + error.toString());
            });
          } catch (error) {
            this.logger.logError(`Error endOperation: task=${this.taskId} error=${error.message}`);
          }
        },
      };
    } catch (err) {
      this.logger.logError(`Error creating endOperation: task=${this.taskId} error=${err.message}`);
    }
  }

  wrapUpOperation() {
    try {
      return {
        operationName: 'Complete',
        icon: new URL(this.iconPack + 'Complete_WrapUp_TwilioFlex.png'),
        title: 'Complete',
        handler: async (operationName, operationMetadata) => {
          try {
            clearTimeout(this.autoInTimer);
            this.logger.logDebug(`wrapUpOperation taskId=${this.taskId}`);
            this.completeTask();
            this.setInteractionState(ContactCanvasChannelApi.INTERACTION_STATES.Disconnected);
          } catch (e) {
            sendNotification('Wrap-Up failed.', NOTIFICATION_TYPE.Error);
            this.logger.logError(`Error wrapUpOperation: task=${this.taskId} error=${e.message}`);
          }
        },
      };
    } catch (e) {
      sendNotification('Wrap-Up failed.', NOTIFICATION_TYPE.Error);
      this.logger.logError(`Error creating wrapUpOperation: task=${this.taskId} error=${e.message}`);
    }
  }

  requeueTaskOperation() {
    try {
      return {
        operationName: 'Requeue',
        icon: new URL(this.iconPack + 'Requeue_Callback_Twilioflex.png'),
        title: 'Requeue',
        handler: async (operationName, operationMetadata) => {
          try {
            clearTimeout(this.autoInTimer);
            this.logger.logDebug(`requeueTaskOperation started taskId=${this.taskId}`);
            let workflowSid;
            this.logger.logDebug(`requeueTaskOperation - checking for workflowTargetSid: taskId=${this.taskId}`);
            if (
              this.interaction.details.fields.workflowTargetSid.Value != null &&
              this.interaction.details.fields.workflowTargetSid.Value
            ) {
              this.logger.logDebug(`requeueTaskOperation - workflowTargetSid found: taskId=${this.taskId}`);
              workflowSid = this.interaction.details.fields.workflowTargetSid.Value;
              const state = this.manager.store.getState();
              const task = state.flex.worker.tasks.get(this.taskKey);
              async function transferTask(url = '', data = {}) {
                const response = await fetch(url, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(data),
                });
                return await response.json();
              }
              this.logger.logDebug(`requeueTaskOperation - start transferTask: taskId=${this.taskId}`);
              const mgr = Flex.Manager.getInstance();
              if (this.callbackServiceUrl != null && this.callbackServiceUrl) {
                const postUrl = `https://${this.callbackServiceUrl}/inqueue-utils`;
                transferTask(postUrl, {
                  mode: 'requeueTasks',
                  type: 'callback',
                  Token: mgr.user.token,
                  taskSid: this.taskId,
                  attributes: this.attributes,
                  workflowSid: workflowSid,
                  queueName: task.queueName,
                  state: false,
                })
                  .then(() => {
                    console.log('==== requeue web service success ===');
                    this.logger.logDebug(`requeueTaskOperation - transferTask complete: taskId=${this.taskId}`);
                    this.setInteractionState(ContactCanvasChannelApi.INTERACTION_STATES.Disconnected);
                  })
                  .catch((error) => {
                    console.log('requeue web service error', error);
                    this.logger.logError('Error requeueTaskOperation : ' + error.toString());
                  });
              } else {
                sendNotification('CallbackDomain not configured.');
              }
            }
          } catch (e) {
            sendNotification('Requeue failed.', NOTIFICATION_TYPE.Error);
            this.logger.logError(`Error requeueTaskOperation: task=${this.taskId} error=${e.message}`);
          }
        },
      };
    } catch (e) {
      sendNotification('Requeue failed.', NOTIFICATION_TYPE.Error);
      this.logger.logError(`Error creating requeueTaskOperation: task=${this.taskId} error=${e.message}`);
    }
  }
  agentTransferOperation() {
    try {
      return {
        operationName: 'agentTransfer',
        icon: new URL(this.iconPack + 'voice_blindtransfer_normal.png'),
        title: 'Transfer to agent',
        handler: async (operationName, operationMetadata) => {
          try {
            this.toggleCallControls();
            this.logger.logDebug(`agentTransferOperation taskId=${this.taskId}`);

            if (!this.props.config.daVinciAppConfig.variables.EnableLivePresence) {
              await addContextualContacts(
                this.props.workers.map((worker) => ({
                  firstName: worker.friendly_name,
                  uniqueId: worker.sid,
                  channels: [],
                }))
              ).catch((error) => {
                this.logger.logDebug('Error in queueTransferOperation, failure in action addContextualContacts : ' + error.toString());
              });
            }

            let contactId;
            try {
              let contact = await ContactCanvasChannelApi.contextualOperation(
                ContactCanvasChannelApi.CONTEXTUAL_OPERATION_TYPE.BlindTransfer
              ).catch((error) => {
                if (error !== 'Canceled by user!') {
                  sendNotification('Transfer failed.', NOTIFICATION_TYPE.Error);
                }
              });
              contactId = getContactsId(contact);
            } catch (e) {
              this.logger.logDebug(`agentTransferOperation canceled taskId=${this.taskId}`);
            }
            this.logger.logDebug(`agentTransferOperation contact=${contactId} taskId=${this.taskId}`);

            if (contactId) {
              this.logger.logDebug(`agentTransferOperation worker=${contactId} taskId=${this.taskId}`);
              const state = this.manager.store.getState();
              const task = state.flex.worker.tasks.get(this.taskKey);
              await task
                .transfer(contactId, {
                  mode: 'COLD',
                })
                .catch((error) => {
                  this.logger.logDebug('Error in agentTransferOperation, failure in action transfer : ' + error.toString());
                  if (error !== 'Canceled by user!') {
                    sendNotification('Transfer failed.', NOTIFICATION_TYPE.Error);
                  }
                });
              this.taskWasTransferredToAgent = true;
            } else {
              this.logger.logDebug(`agentTransferOperation no worker found taskId=${this.taskId}`);
            }
          } catch (e) {
            this.logger.logError(`Error agentTransferOperation: task=${this.taskId} error=${e.message}`);
          } finally {
            this.toggleCallControls();
            clearContextualContacts();
          }
        },
      };
    } catch (e) {
      this.logger.logError(`Error creating agentTransferOperation: task=${this.taskId} error=${e.message}`);
    }
  }
  queueTransferOperation() {
    try {
      return {
        operationName: 'queueTransfer',
        icon: new URL(this.iconPack + 'work_transfertoqueue.png'),
        title: 'Transfer to queue',
        handler: async (operationName, operationMetadata) => {
          try {
            this.toggleCallControls();
            this.logger.logDebug(`queueTransferOperation taskId=${this.taskId}`);
            await addContextualContacts(
              this.props.taskQueues.map((queue) => ({
                firstName: queue.friendly_name,
                uniqueId: queue.sid,
                channels: [],
              }))
            ).catch((error) => {
              this.logger.logDebug('Error in queueTransferOperation, failure in action addContextualContacts : ' + error.toString());
            });

            let queueSid;
            try {
              let contact = await ContactCanvasChannelApi.contextualOperation(
                ContactCanvasChannelApi.CONTEXTUAL_OPERATION_TYPE.BlindTransfer
              ).catch((error) => {
                if (error !== 'Canceled by user!') {
                  sendNotification('Transfer failed.', NOTIFICATION_TYPE.Error);
                }
              });
              queueSid = getContactsId(contact);
            } catch (e) {
              this.logger.logDebug(`queueTransferOperation canceled taskId=${this.taskId}`);
            }
            this.logger.logDebug(`queueTransferOperation contact=${queueSid} taskId=${this.taskId}`);

            if (queueSid) {
              this.logger.logDebug(`queueTransferOperation queue=${queueSid} taskId=${this.taskId}`);
              const state = this.manager.store.getState();
              const task = state.flex.worker.tasks.get(this.taskKey);
              task
                .transfer(queueSid, {
                  mode: 'COLD',
                })
                .catch((error) => {
                  this.logger.logDebug('Error in queueTransferOperation, failure in action transfer : ' + error.toString());
                  if (error !== 'Canceled by user!') {
                    sendNotification('Transfer failed.', NOTIFICATION_TYPE.Error);
                  }
                });
            }
          } catch (e) {
            this.logger.logError(`Error queueTransferOperation: task=${this.taskId} error=${e.message}`);
          } finally {
            this.toggleCallControls();
            clearContextualContacts();
          }
        },
      };
    } catch (e) {
      this.logger.logError(`Error creating queueTransferOperation: task=${this.taskId} error=${e.message}`);
    }
  }
  playDTMFOperation() {
    try {
      return {
        operationName: 'DTMF',
        icon: new URL(this.iconPack + 'Dialpad.png'),
        title: 'Show DTMF',
        handler: async (operationName, operationMetadata) => {
          try {
            this.toggleCallControls();
            this.logger.logDebug(`playDTMFOperation taskId=${this.taskId}`);
            await ContactCanvasChannelApi.contextualOperation(
              ContactCanvasChannelApi.CONTEXTUAL_OPERATION_TYPE.DTMF,
              ContactCanvasChannelApi.CHANNEL_TYPES.Telephony,
              async (contact) => {
                await Flex.Actions.invokeAction('SendDTMFDigits', {
                  sid: this.taskKey,
                  digits: contact.uniqueId,
                });
              }
            )
              .then(async (contact) => {})
              .catch((error) => {
                this.logger.logError('playDTMFOperation - Unable to play DTMF tone: ' + error);
              });
          } catch (e) {
            this.logger.logError(`Error playDTMFOperation: task=${this.taskId} error=${e.message}`);
          } finally {
            this.toggleCallControls();
            clearContextualContacts();
          }
        },
      };
    } catch (e) {
      this.logger.logError(`Error creating playDTMFOperation: task=${this.taskId} error=${e.message}`);
    }
  }

  render() {
    const { manager } = this.props;

    if (!manager || !this.state.visible) {
      return null;
    }
    if (this.useAMCUI) {
      return <amc-webcomponents-scenario ref={(elem) => (this.nv = elem)} />;
    } else {
      return null;
    }
  }
}
