import * as $ from "jquery";
import * as Flex from "@twilio/flex-ui";
import * as FlexStore from "./FlexStore";
import * as amc from "@amc-technology/davinci-api";

import { ILoggerConfiguration, defaultLoggerConfiguration } from '@amc-technology/davinci-api/dist/models/LoggerConfiguration';

import React from "react";
import { Scenario } from "./Scenario";
import { getContactsId } from "./util";

export class AMCRoot extends React.Component {
  constructor(props) {
    super(props);
    this.toggleCallControls = this.toggleCallControls.bind(this);
    this.storeListener = this.storeListener.bind(this);

    this.state = {
      tasks: [],
      apiUrl: props.config.serverConfig.apiUrl,
      iconPack: props.config.serverConfig.iconPack,
      shouldDisableCallControls: false,
      loggerApiUrl: props.config.serverConfig.loggerApiUrl,
    };

    this.props = props;
    if (
      this.props.config.daVinciAppConfig &&
      this.props.config.daVinciAppConfig.CADDisplay &&
      this.props.config.daVinciAppConfig.CADDisplay.variables
    ) {
      this.cadDisplay = this.props.config.daVinciAppConfig.CADDisplay.variables;
    }

    if (
      this.props.config.daVinciAppConfig.variables &&
      this.props.config.daVinciAppConfig.variables.UseAMCUI === false
    ) {
      this.useAMCUI = false;

      // Override React functions here
      this.setState = (data) => {
        const fname = 'setState';
        const cname = 'AMCRoot';

        try {
          this.logger.logTrace(`${cname} - ${fname} - START`);

          for (const key in data) {
            this.logger.logLoop(`${cname} - ${fname} - Processing key ${key}`);

            if (data.hasOwnProperty(key)) {
              // eslint-disable-next-line react/no-direct-mutation-state
              this.state[key] = data[key];
            }
          }
        } catch (e) {
          this.logger.logError(`${cname} - ${fname} - Error: ${JSON.stringify(e)}`);
        } finally {
          this.logger.logTrace(`${cname} - ${fname} - END`);
        }
      }
    } else {
      this.useAMCUI = true;
    }

    this._lastHeight = 0;
    this.finishedTasks = {};
    this.syncPresenceSIDtoFramework = undefined;
    this.syncPresenceSIDtoTwilio = undefined;
    this.serviceBaseUrl = `https://${this.props.manager.serviceConfiguration.runtime_domain}`;

    this.initializeLogger();

    $.ajax({
      url: `https://taskrouter.twilio.com/v1/Workspaces/${props.manager.serviceConfiguration["taskrouter_workspace_sid"]}/Workers`,
      headers: {
        Authorization:
          "Basic " +
          btoa(
            props.config.daVinciAppConfig.variables.AccountSID +
              ":" +
              props.config.daVinciAppConfig.variables.AuthToken
          ),
      },
    }).then((result) => this.setState({ workers: result.workers }));
    $.ajax({
      url: `https://taskrouter.twilio.com/v1/Workspaces/${props.manager.serviceConfiguration["taskrouter_workspace_sid"]}/TaskQueues`,
      headers: {
        Authorization:
          "Basic " +
          btoa(
            props.config.daVinciAppConfig.variables.AccountSID +
              ":" +
              props.config.daVinciAppConfig.variables.AuthToken
          ),
      },
    }).then((result) => this.setState({ taskQueues: result.task_queues }));

    amc.registerOnPresenceChanged((presence, reason, appName) => {
      if (appName !== this.props.config.daVinciAppConfig.name) {
        if (
          this.syncPresenceSIDtoFramework ===
            this.props.config.daVinciAppConfig.variables.WorkMode[presence] &&
          this.syncPresenceSIDtoTwilio ===
            this.props.config.daVinciAppConfig.variables.WorkMode[presence]
        ) {
          this.logger.logDebug(
            "Skip Set Presence, already in " +
              presence +
              " " +
              this.props.config.daVinciAppConfig.variables.WorkMode[presence]
          );
        } else {
          try {
            const activity = this.props.config.daVinciAppConfig.variables
              .WorkMode[presence];
            this.logger.logDebug(
              `on presence changed; presence=${presence}; activity=${activity}`
            );
            if (
              this.props.config.daVinciAppConfig.variables.WorkMode[presence]
            ) {
              Flex.Actions.invokeAction("SetActivity", {
                activitySid: activity,
              }).catch((error) =>
                this.logger.logError(`Error12; ${error.message}`)
              );
              this.syncPresenceSIDtoTwilio = this.props.config.daVinciAppConfig.variables.WorkMode[
                presence
              ];
            }
          } catch (e) {
            this.logger.logError(`Error on presence changed: ${e.message}`);
          }
        }
        return Promise.resolve();
      }
    });

    amc.registerOnLogout(async () => {
      try {
        this.logger.logDebug("Logout");
        await this.logger.pushLogsAsync();
        Flex.Actions.invokeAction("Logout", {
          activitySid: this.props.config.daVinciAppConfig.variables.WorkMode[
            "Logout"
          ],
        });
        this.syncPresenceSIDtoTwilio = this.props.config.daVinciAppConfig.variables.WorkMode[
          "Logout"
        ];
      } catch (exception) {
        this.logger.logError(`Error on logout: ${exception.message}`);
      }

      return Promise.resolve();
    });

    if (this.props.config.daVinciAppConfig.variables.EnableLivePresence) {
      const state = this.props.manager.store.getState();
      const workerSid = state.flex.worker.worker.sid;
      amc
        .setSupportedChannels([
          {
            channelType: amc.CHANNEL_TYPES.Telephony,
            idName: "worker",
            id: workerSid,
            validOperations: [
              amc.CONTEXTUAL_OPERATION_TYPE.BlindTransfer,
              amc.CONTEXTUAL_OPERATION_TYPE.Conference,
              amc.CONTEXTUAL_OPERATION_TYPE.Consult,
              amc.CONTEXTUAL_OPERATION_TYPE.Other,
              amc.CONTEXTUAL_OPERATION_TYPE.WarmTransfer,
            ],
            validPresences:
              this.props.config.daVinciAppConfig.variables
                .LivePresenceValidPresences || [],
            customValues: {
              token: this.props.manager.user.token,
              workerSID: this.props.manager.workerClient["sid"],
              workerURI: this.props.manager.workerClient.attributes[
                "contact_uri"
              ],
            },
          },
        ])
        .catch((error) =>
          this.logger.logError(
            `AMCRoot - setSupportedChannels problem: ${error.message}`
          )
        );
    }

    amc
      .initializeComplete(this.logger)
      .then(() => {
        amc.addContextualContacts([]);
        this.resize();
        amc.enableClickToDial(true);
        this.logger.logDebug("initializeComplete");
      })
      .catch((error) =>
        this.logger.logError(`AMCRoot - initializeComplete - ${error.message} `)
      );

    amc.registerContextualControls((contact) => {
      try {
        const toDial = getContactsId(contact);
        this.logger.logDebug(
          `Register Contextual Controls : Outbound : phone number =${toDial}`
        );
        this.clickToDial(toDial);
      } catch (error) {
        this.logger.logError(
          `RegisterContextualControls callback : Error = ${error.message}`
        );
      }

      return Promise.resolve();
    });

    amc.registerClickToDial((phoneNumber, records) => {
      try {
        this.logger.logDebug(
          `Register Click to Dial : phone number = ${phoneNumber}`
        );
        this.clickToDial(phoneNumber);
      } catch (error) {
        this.logger.logError(
          `Error on registerClickToDial: Error = ${error.message}`
        );
      }

      return Promise.resolve();
    });

    // Set starting activity
    Flex.Actions.invokeAction("SetActivity", {
      activitySid: this.props.config.daVinciAppConfig.variables.WorkMode[
        "Not Ready"
      ],
    });

    if (!this.useAMCUI) {
      this.componentDidMount();
    }
  }

  async initializeLogger() {
    const functionName = 'initializeLogger';
    let logger;
    const config = await this.getClientConfig();
    try {
      // Get raw config. Framework does not perform data validation intentionally
      const rawConfig = await amc.getDaVinciAgentConfig();

      // Perform data validation, revert to defaults if configs are invalid
      // or undefined
      let logLevel = parseInt(rawConfig?.variables?.['Log Level']?.toString(), 10);

      logLevel = isNaN(logLevel) ? defaultLoggerConfiguration['Log Level'] : logLevel;

      let maxLength = parseInt(rawConfig?.['Console Logger']?.variables?.['Max Length']?.toString(), 10);

      maxLength = isNaN(maxLength) ? defaultLoggerConfiguration['Console Logger']['Max Length'] : maxLength;

      const loggerConfig = {
        'Log Level': logLevel,
        'Logger Type': rawConfig?.variables?.['Logger Type']?.toString() || defaultLoggerConfiguration['Logger Type'],
        'Premise Logger URL': rawConfig?.variables?.['Premise Logger URL']?.toString() || defaultLoggerConfiguration['Premise Logger URL'],
        'Console Logger': {
          'Max Length': maxLength,
        },
      };

      logger = new amc.Logger(amc.LOG_SOURCE.TwilioApp, false, config.loggerApiUrl);
      logger.setConfiguration(loggerConfig);
    } catch (err) {
      logger = new amc.Logger(amc.LOG_SOURCE.TwilioApp, false, config.loggerApiUrl);

      logger.logCritical('loggerService.initialize(): Error creating logger!');
    } finally {
      this.logger = logger;
    }
  }


  async getClientConfig() {
    try {
      let response = await fetch(`${window.location.origin.toString()}/ClientConfiguration`);
      let config = await response.json().catch((error) => {
        console.log('Error pulling the ClientConfiguration' + error);
      });
      if (config) {
        return config;
      }
    } catch (error) {
      throw error;
    }
  }

  async componentDidMount() {
    try {
      FlexStore.init(this.props.manager.store);
      FlexStore.subscribe(this.storeListener);
      await this.storeListener();
      this.resize();
    } catch (error) {
      this.logger.logError(
        `Error on componentDidMount: Error = ${error.message}`
      );
    }
  }
  componentDidUpdate() {
    this.resize();
  }

  async storeListener() {
    try {
      const state = this.props.manager.store.getState();
      await this.syncAgentState(state);
      const tasks = [];
      const finishedTasks = this.finishedTasks;
      state.flex.worker.tasks.forEach((task, key) => {
        const attributesString =
          task.attributes !== undefined
            ? JSON.stringify(task.attributes)
            : "undefined";
        this.logger.logDebug(
          `Handling Task : SID = ${task.taskSid}, Task attributes = ${attributesString}`
        );
        const channel = task.source.taskChannelUniqueName;
        if (
          (channel === "voice" ||
            channel === "chat" ||
            channel === "sms" ||
            channel === "callback") &&
          !finishedTasks[task.attributes.from]
        ) {
          this.logger.logDebug(
            `Handling Task : SID = ${task.taskSid}, Task attributes = ${attributesString}`
          );
          let taskAssociatedData = [];
          if (
            task.attributes &&
            this.cadDisplay &&
            this.cadDisplay.DisplayCAD
          ) {
            for (const key in this.cadDisplay.DisplayKeyList) {
              if (task.attributes[key]) {
                taskAssociatedData.push({
                  displayKey: this.cadDisplay.DisplayKeyList[key],
                  displayValue: task.attributes[key],
                  displayValueType: null,
                  visible: true,
                  displayOperation: null,
                });
              }
            }
          }
          this.logger.logDebug(
            `Sending Task To Twilio : taskSid = ${task.taskSid}, taskKey = ${key},` +
              ` attributes = ${attributesString}, associatedData = ${taskAssociatedData},` +
              ` workerSID = ${state.flex.worker.worker.sid}, flexToken = ${this.props.manager.user.token},` +
              ` workerURI = ${state.flex.worker.worker.attributes["contact_uri"]}`
          );
          tasks.push({
            taskSid: task.taskSid,
            taskKey: key,
            attributes: task.attributes,
            associatedData: taskAssociatedData,
            workerSID: state.flex.worker.worker.sid,
            flexToken: this.props.manager.user.token,
            workerURI: state.flex.worker.worker.attributes["contact_uri"],
          });
        }
      });
      tasks.sort();

      if (!this.arraysAreEqual(this.state.tasks, tasks)) {
        this.setState({ tasks: tasks });

        if (!this.useAMCUI) {
          const { manager } = this.props;

          if (!manager || !this.state.iconPack) {
            return null;
          }

          for (const task of tasks) {
            new Scenario({
              manager:manager,
              key:task.taskKey,
              taskId:task.taskSid,
              taskKey:task.taskKey,
              iconPack:this.state.iconPack,
              removeSelf:this.createRemoveScenario(task),
              resize:this.resize,
              workers:this.state.workers,
              logger:this.logger,
              taskQueues:this.state.taskQueues,
              associatedData:task.associatedData,
              toggleCallControls:this.toggleCallControls,
              config:this.props.config,
              flexToken:task.flexToken,
              workerSID:task.workerSID,
              workerURI:task.workerURI,
              attributes:task.attributes,
            })
          }

          return null;
        }
      }
    } catch (error) {
      this.logger.logError(`Store listener, Error = ${error.message}`);
    }
  }

  arraysAreEqual(a, b) {
    return JSON.stringify(a) === JSON.stringify(b);
  }

  createRemoveScenario(task) {
    return (taskWasTransferredToAgent) => {
      try {
        if (taskWasTransferredToAgent) {
          // This is a hacky fix for transferring tasks to agents.
          // We receive a new task when transfer occurs
          // Here we are ignoring tasks from the same phone number to avoid new setInteractions
          this.finishedTasks[task.attributes.from] = true;
          setTimeout(
            () => delete this.finishedTasks[task.attributes.from],
            4 * 1000
          );
        }

        const tasks = this.state.tasks.filter(
          (aTask) => aTask.taskSid !== task.taskSid
        );

        if (!this.arraysAreEqual(this.state.tasks, tasks)) {
          this.setState({ tasks: tasks });
        } else {
          this.setState(null);
        }
      } catch (error) {
        this.logger.logError(`createRemoveScenario, Error = ${error.message}`);
      }
    };
  }

  async syncAgentState(state) {
    try {
      // Only Sync presence if Logout is not raised
      if (
        this.syncPresenceSIDtoFramework !==
        this.props.config.daVinciAppConfig.variables.WorkMode["Logout"]
      ) {
        // If Logout is not triggered but, Flex is still offline and this.syncPresenceSID is still not initialized then, Sync the state from DaVinci Agent to Flex
        if (
          state.flex.worker.activity.sid ===
            this.props.config.daVinciAppConfig.variables.WorkMode["Logout"] &&
          this.syncPresenceSIDtoFramework === undefined
        ) {
          try {
            amc.getPresence().then((data) => {
              if (
                this.syncPresenceSIDtoFramework !==
                this.props.config.daVinciAppConfig.variables.WorkMode[
                  data.presence
                ]
              ) {
                try {
                  Flex.Actions.invokeAction("SetActivity", {
                    activitySid: this.props.config.daVinciAppConfig.variables
                      .WorkMode[data.presence],
                  });
                  this.syncPresenceSIDtoFramework = this.props.config.daVinciAppConfig.variables.WorkMode[
                    data.presence
                  ];
                } catch (error) {
                  this.logger.logError(
                    "Sync Presence from DaVinci To Flex - SetActivity, Error" +
                      error.message
                  );
                }
                this.logger.logInformation(
                  "Sync Presence from DaVinci To Flex, " +
                    data.presence +
                    " " +
                    this.props.config.daVinciAppConfig.variables.WorkMode[
                      data.presence
                    ]
                );
              } else {
                this.logger.logDebug(
                  "Skip Sync Presence - already in " +
                    data.presence +
                    " " +
                    this.props.config.daVinciAppConfig.variables.WorkMode[
                      data.presence
                    ]
                );
              }
              return Promise.resolve();
            });
          } catch (error) {
            this.logger.logError(
              `Sync Presence from DaVinci To Flex, Error = ${error.message}`
            );
          }
        } else if (
          state.flex.worker.activity.sid ===
            this.props.config.daVinciAppConfig.variables.WorkMode["Logout"] &&
          this.syncPresenceSIDtoFramework !== undefined
        ) {
          // Agent logged out of Flex from Twilio side; Logout DaVinci Agent
          try {
            await this.logger.pushLogsAsync();
            amc.logout();
            this.syncPresenceSIDtoFramework = state.flex.worker.activity.sid;
          } catch (error) {
            this.logger.logError(
              "Sync Presence logout from Flex, Error" + error.message
            );
          }
        } else {
          // Sync the state from Flex to DaVinci Agent
          if (
            this.syncPresenceSIDtoFramework !== state.flex.worker.activity.sid
          ) {
            try {
              for (var property in this.props.config.daVinciAppConfig.variables
                .WorkMode) {
                if (
                  !this.props.config.daVinciAppConfig.variables.WorkMode.hasOwnProperty(
                    property
                  )
                )
                  continue;

                if (
                  this.props.config.daVinciAppConfig.variables.WorkMode[
                    property
                  ] === state.flex.worker.activity.sid
                ) {
                  this.syncPresenceSIDtoFramework =
                    state.flex.worker.activity.sid;
                  amc.setPresence(property);
                  this.logger.logInformation(
                    "Sync Presence from Flex to DaVinci " +
                      property +
                      " " +
                      state.flex.worker.activity.sid
                  );
                  break;
                }
              }
            } catch (error) {
              this.logger.logError(
                `Sync Presence from Flex to DaVinci, Error = ${error.message}`
              );
            }
          }
        }
      }
    } catch (error) {
      this.logError.logError(`AMCRoot - synAgentState : ${error.message}`);
    }
  }

  /**
   * getAgentCallerId
   *
   * @param {object} Manager              Flex Manager
   * @param {object} [configVariables] parameters configuration object
   */
  getAgentCallerId(Manager, configVariables) {
    try {
      if (
        Manager.workerClient &&
        Manager.workerClient.attributes &&
        Manager.workerClient.attributes.callerId
      ) {
        return Manager.workerClient.attributes.callerId;
      }

      return (
        (configVariables && configVariables.OutboundCallerId) || "NO_CALLER_ID"
      );

    } catch (error) {
      this.logger.logError(`AMCRoot - getAgentCallerId : ${error.message}`);
    }
  }

  resize() {
    try {
      const height = document.body.scrollHeight + 1;
      if (height !== this._lastHeight) {
        this.logger.logDebug("Updating height");
        this._lastHeight = height;

        if (this.useAMCUI) {
          amc.setAppHeight(height);
        }
      }
    } catch (e) {
      this.logger.logError(`Error on presence changed: ${e.message}`);
    }
  }

  toggleCallControls() {
    try {
      this.setState((state) => ({
        shouldDisableCallControls: !state.shouldDisableCallControls,
      }));
    } catch (error) {
      this.logger.logError(`AMCRoot - toggleCallControls: ${error.message}`);
    }
  }

  clickToDial(destinationNumber) {
    // Outbound dial
    try {
      this.logger.logDebug(
        `Sending number to flex : phone number = ${destinationNumber}`
      );
      Flex.Actions.invokeAction("StartOutboundCall", {
        destination: destinationNumber,
      });
    } catch (error) {
      this.logger.logError(`Error on initiating call: ${error.message}`);
    }
  }

  render() {
    try {
      const { manager } = this.props;
      if (!manager || !this.state.iconPack) {
        return null;
      }

      return (
        <div
          id="amc-root-twilio"
          className={
            this.state.shouldDisableCallControls ? "disableCallControls" : ""
          }
        >
          {this.state.tasks.map((task) => {
            return (
              <Scenario
                manager={manager}
                key={task.taskKey}
                taskId={task.taskSid}
                taskKey={task.taskKey}
                iconPack={this.state.iconPack}
                removeSelf={this.createRemoveScenario(task)}
                resize={this.resize}
                workers={this.state.workers}
                logger={this.logger}
                taskQueues={this.state.taskQueues}
                associatedData={task.associatedData}
                toggleCallControls={this.toggleCallControls}
                config={this.props.config}
                flexToken={task.flexToken}
                workerSID={task.workerSID}
                workerURI={task.workerURI}
                attributes={task.attributes}
              />
            );
          })}
        </div>
      );

    } catch (error) {
      this.logger.logError(`AMCRoot - render: ${error.message}`);
    }
  }
}
