import React from 'react';
import * as Flex from '@twilio/flex-ui';

class AppFlex extends React.Component {
  render() {
    const { manager } = this.props;

    if (!manager) {
      return null;
    }

    return (
      <Flex.ContextProvider manager={manager}>
        <Flex.RootContainer/>
      </Flex.ContextProvider>
    );
  }
}

export default AppFlex;
