/**
 * This is used to allow the flex store to be unsubscribed too
 */

let subscribers = [];

export function subscribe(listener) {
  subscribers.push(listener);
}

export function unsubscribe(listener) {
  subscribers = subscribers.filter(el => el !== listener);
}

export function init(store) {
  store.subscribe(() => {
    const args = arguments;
    subscribers.forEach(subscriber => subscriber(...args));
  });
}
